.paper {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    background: #163647;
    justify-content: center;
    transform: translate(-50%, -50%);
  }

.container {
    width: "100vw";
    z-index: 1000;
    position: "absolute";
}

.text {
    margin: "15px 0";
    letter-spacing: 1;
    color:#00FFFF;
    font-weight: 600;
    font-size: large;
}

.loader {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #00FFFF;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 